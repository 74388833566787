import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo, useEffect, useRef } from 'react'

import { Youtube } from './Youtube'

export interface Props {
  claim?: string
  cta?: ButtonProps
  image?: ImageProps
  isActive?: boolean
  youtubeID?: string
  payoff?: string
  style?: any
  videoURL?: string
}

export const Slide = memo(function Slide({
  claim,
  cta,
  image,
  isActive,
  youtubeID,
  payoff,
  style,
  videoURL,
}: Props) {
  if (!image && !videoURL && !youtubeID) {
    return null
  }

  const videoElement = useRef<any>(null)

  useEffect(() => {
    if (videoElement.current) {
      if (isActive) {
        videoElement.current.play()
      } else {
        videoElement.current.pause()
      }
    }
  }, [isActive])

  return (
    <Container className={isActive ? 'active' : undefined} style={style}>
      {youtubeID ? (
        <Youtube ID={youtubeID} />
      ) : videoURL ? (
        <Video ref={videoElement} loop muted playsInline preload="auto">
          <source src={videoURL} type="video/mp4" />
        </Video>
      ) : image ? (
        <Image media="(min-width: 2800px)" {...image} />
      ) : null}

      <Wrapper className={isActive ? 'active' : undefined}>
        {payoff ? <Payoff>{payoff}</Payoff> : null}
        {claim ? <Claim>{claim}</Claim> : null}
        {cta ? <CTA variant="primary" {...cta} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.8s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 4.167vw;
  left: 4.167vw;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(-50%);
  z-index: 3;
  &.active {
    opacity: 1;
    transition: 0.3s 1s ease-in-out;
  }
`

const Payoff = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;

  @media (max-width: 574px) {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
`

const Claim = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 0.5rem;
`

const CTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 1.875rem;
  &:before,
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
